require('babel-polyfill');
const React = require('react');

const PropTypes = require('prop-types');
const { Head } = require('nordic/head');
const serialize = require('serialize-javascript');

const injectI18n = require('nordic/i18n/injectI18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const MeliGA = require('nordic/analytics/meli-ga');
const { MelidataTrack } = require('nordic/melidata/melidata-track');
const { Script } = require('nordic/script');
const { Style } = require('nordic/style');
const Appearance = require('@mshops-web-core/appearance').default;

const canUseDOM = require('../../../utils/enabledDOM');

const HomeLayout = require('./HomeLayout');
const RenderTree = require('../../../appearance/RenderTree').default;
const nonEditableComponentList = require('./nonEditables');

const { RenderProvider } = require('../context');

const { HOME, CUSTOM_CONTENT_PAGE } = require('../../../utils/constants/sections');
const { CartProvider } = require('../../marketplaceHome/context/cart');

const { FreeShippingProvider } = require('../../marketplaceHome/context/freeShipping');

const { useEffect } = React;

function HomeView({
  i18n,
  translations = {},
  site = 'MS',
  siteId,
  lowEnd,
  deviceType,
  company,
  shopModel = {
    appearance: {
      properties: {},
    },
  },
  config,
  editable = false,
  section,
  siteKey,
  apiBasePath,
  hideModalSubscription,
  isHubPreview,
  manifest = {},
  assetsPrefix = '',
  uuidVersion = '',
  locale,
  device,
  features,
  csrfToken,
}) {
  // eslint-disable-next-line
  const { appearance, fiscal_data: fiscalData, shop, storefront, contact } = shopModel;
  const { currency, title } = appearance.properties;
  const { restClient } = config;
  const ShopTypeId = storefront?.id ?? shop.id;

  const appearanceService = new Appearance(appearance);
  const layoutTheme = appearanceService.getComponentProperty('Document', 'theme');
  const metaTags = appearanceService.getComponentProperty('Document', 'meta_tags');

  const serializableProps = {
    translations,
    site,
    siteId,
    lowEnd,
    deviceType,
    company,
    shopModel,
    config,
    editable,
    section,
    siteKey,
    apiBasePath,
    hideModalSubscription,
    isHubPreview,
    manifest,
    assetsPrefix,
    uuidVersion,
    locale,
    device,
    features,
    csrfToken,
  };

  const customDimensions = { collectorId: ShopTypeId };
  const page = (section === HOME) ? 'MAIN' : section.toUpperCase();

  useEffect(() => {
    if (canUseDOM() && window.tracking_mediator) {
      window.tracking_mediator(
        'dispatch',
        `page:${window.__PRELOADED_STATE__.section}`,
        window.__PRELOADED_STATE__.shopModel.appearance,
      );
      window.tracking_mediator(
        'dispatch',
        `gtm:${window.__PRELOADED_STATE__.section}`,
        { page, section },
      );
    }
  }, []);

  const renderProviderInitialValue = {
    theme: layoutTheme,
    fiscalData,
    contact,
    socialNetworks: shop?.social_networks,
    appearance: new Appearance(appearance),
    initial: new Appearance(appearance),
    device: deviceType,
    apiBasePath,
    draftMode: !!(uuidVersion),
    numberStaticComponentsTop: 0,
    shopModel,
    currency,
    siteId,
    lowEnd,
    siteKey,
    restClient,
    hideModalSubscription,
    isHubPreview,
    features,
  };

  const layout = appearanceService.getTree()?.children[1]?.children;
  const hasTabbedCarousel = !!layout.find(component => component.component === 'TabbedCarousel');
  const hasCollectionGrid = !!layout.find(component => component.component === 'CollectionGrid');

  const eventData = {
    seller_id: shopModel.shop?.id,
    has_tabbed_carrousel: hasTabbedCarousel,
    has_collection_grid: hasCollectionGrid,
  };

  return (
    <>
      <MeliGA
        siteId={siteId}
        platform={site}
        section="HOME"
        business="SHOPS"
        page={`/${page}/`}
        dimensions={customDimensions}
      />
      <MelidataTrack
        path={section === CUSTOM_CONTENT_PAGE ? '/shop_custom_content' : '/home'}
        event_data={eventData}
      />
      <Head>
        <title>{title}</title>
        {(metaTags && metaTags.length)
          ? metaTags.map((meta) => <meta key={meta.type} name={meta.type} content={meta.content} />)
          : null
        }
      </Head>
      <Style href={`${layoutTheme}/home-${layoutTheme}-${deviceType}.css`} />
      <Script>
        {`
          window.__PRELOADED_STATE__ = ${serialize(serializableProps, { isJSON: true }) || {}};
        `}
      </Script>
      <Script src="@frontend-performance/image-lazy-loading/src.js" on="now" inline />

      <I18nProvider i18n={i18n}>
        <RenderProvider value={renderProviderInitialValue}>
          <CartProvider>
            <FreeShippingProvider>
              <HomeLayout
                section={section}
                isCustomHTML={shopModel?.appearance?.properties?.custom}
                shopName={shopModel?.shop?.name}
              >
                <RenderTree componentsList={nonEditableComponentList} i18n={i18n} />
              </HomeLayout>
            </FreeShippingProvider>
          </CartProvider>
        </RenderProvider>
      </I18nProvider>
    </>
  );
}

HomeView.propTypes = {
  i18n: PropTypes.shape().isRequired,
  translations: PropTypes.shape(),
  site: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  shopModel: PropTypes.shape(),
  config: PropTypes.shape().isRequired,
  editable: PropTypes.bool,
  section: PropTypes.string.isRequired,
  siteKey: PropTypes.string.isRequired,
  apiBasePath: PropTypes.string.isRequired,
  hideModalSubscription: PropTypes.bool.isRequired,
  isHubPreview: PropTypes.bool.isRequired,
  manifest: PropTypes.shape(),
  assetsPrefix: PropTypes.string,
  uuidVersion: PropTypes.string,
  locale: PropTypes.string.isRequired,
  device: PropTypes.shape().isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  csrfToken: PropTypes.string.isRequired,
};

module.exports = injectI18n((HomeView));
