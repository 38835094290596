export const CART_BY_SHOP_TYPE = {
  eshop: {
    getCartItems: {
      path: '/cart-super',
    },
    addToCart: {
      path: '/cart-super-add-item',
    },
    updateCartItem: {
      path: '/cart-super-update-item',
    },
    deleteCartItem: {
      path: '/cart-super-delete-item',
    },
  },
  mshops: {
    getCartItems: {
      path: '/cart/cart',
    },
    addToCart: {
      path: '/cart/add-item',
    },
    updateCartItem: {
      path: '/cart/update-item',
    },
    deleteCartItem: {
      path: '/cart/delete-item',
    },
  },
};
