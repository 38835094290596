const TYPES = {
  SELLER: 'seller',
  AFFILIATE: 'affiliate',
  SUPER: 'super',
  SUPER_PARTNER: 'super_partner',
  BRAND: 'brand',
  UNKNOWN: '',
};

const WITH_PROFILE_HEADER = [
  TYPES.SELLER,
  TYPES.SUPER,
  TYPES.SUPER_PARTNER,
  TYPES.BRAND,
];

const WITH_CATEGORY_SUPER = [
  TYPES.SUPER,
  TYPES.SUPER_PARTNER,
];

const WITHOUT_ACTIONS_IN_ESHOPSITEM = [
  TYPES.SELLER,
  TYPES.BRAND,
];

const ESHOPS_MOBILE_VARIATIONS = [
  TYPES.SELLER,
  TYPES.BRAND,
];

const WITH_POLYCARD = [
  TYPES.SELLER,
  TYPES.BRAND,
  TYPES.AFFILIATE,
];

const WITH_FREE_CAROUSEL = [
  TYPES.SELLER,
  TYPES.BRAND,
];

const WITH_POLYCARD_RECOS = [
  TYPES.SELLER,
  TYPES.BRAND,
];

const WITH_POLYCARD_GALLERY = [
  TYPES.SELLER,
  TYPES.BRAND,
];

module.exports = {
  TYPES,
  WITH_PROFILE_HEADER,
  WITH_CATEGORY_SUPER,
  WITHOUT_ACTIONS_IN_ESHOPSITEM,
  ESHOPS_MOBILE_VARIATIONS,
  WITH_POLYCARD,
  WITH_FREE_CAROUSEL,
  WITH_POLYCARD_RECOS,
  WITH_POLYCARD_GALLERY,
};
