module.exports = {
  CONTACT: 'contact',
  HOME: 'home',
  CUSTOM_CONTENT_PAGE: 'custom_content_page',
  USER_LOGGED: 'user_logged',
  HOW_BUY_LANDING: 'how_buy_landing',
  BANK_CONDITIONS_LANDING: 'bank_conditions_landing',
  DATA_PRIVACY_LANDING: 'data_privacy_landing',
  LEGAL_NOTICES_LANDING: 'legal_notices_landing',
  ADHESION_CONTRACT_LANDING: 'adhesion_contract_landing',
  HOW_CANCEL_LANDING: 'cancel_buy_landing',
  NOT_FOUND: 'not_found',
  ESHOP: 'eshop',
  SOCIAL_HEADER: 'affiliate-marketplace',
  SUPERMARKET_HEADER: 'super-marketplace',
  SELLER_HEADER: 'seller-marketplace',
  BRAND_HEADER: 'brand-marketplace',
  NAVIGATION: 'navigation',
  PROTECTED_PURCHASE_LANDING: 'protected_purchase_landing',
};
